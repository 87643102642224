import Title from "./../components/Title";
import Table from "react-bootstrap/Table";
import Offcanvas from "react-bootstrap/Offcanvas";
import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import "./Information.css";

const styledInfo = {
  paddingLeft: "7%",
  paddingRight: "7%",
};

const styledInfoTable = {
  paddingLeft: "7%",
  paddingRight: "7%",
  marginTop: "1rem",
};

const styledLink = {
  textDecoration: "none",
  color: "var(--bs-light)",
};

const fabStyle = {
  position: "fixed",
  bottom: "3%",
  left: "2%",
};

function NavPane({ name, ...props }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  return (
    <>
      <Fab style={fabStyle} onClick={toggleShow}>
        <MenuIcon />
      </Fab>
      <Offcanvas
        show={show}
        scroll={true}
        restoreFocus={false}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Jump to...</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <details>
            <summary>Offensive Moves</summary>
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#AttackRecoveriesChainLinks"
            >
              Attack Recoveries & Chain Links
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#FrameAdvantage">
              Frame Advantage & Disadvantage
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#GuardbreakAndThrows"
            >
              Guardbreak and Throws
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#AttackGBVulnerability"
            >
              Attack Guard Break Vulnerability
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Feints">
              Feints
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#SoftFeints">
              Soft Feints
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Hyperarmor">
              Hyper Armor & Super Armor
            </a>
          </details>
          <br />
          <details>
            <summary>Defensive Moves</summary>
            <a style={styledLink} onClick={toggleShow} href="#Parrying">
              Parry
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Dodges">
              Dodge I-Frames & Recovery
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Rolls">
              Rolls
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Deflects">
              Deflect
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#BlockDodge">
              Superior Block Dodge
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Blocking">
              Guard Switch & Block/Superior Block
            </a>
            <br />
          </details>
          <br />
          <details>
            <summary>Character States</summary>
            <a style={styledLink} onClick={toggleShow} href="#Shields">
              Shields
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#OutOfStamina">
              Out of Stamina
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#OutOfLock">
              Out-of-Lock
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Staggers">
              Collision Stagger/Knockdown
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#HitReactions">
              Hit, Block & Superior Block Stun
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#DamageAndHitstunRules"
            >
              Damage Reduction & 'Hitstun Stacking' rules
            </a>
          </details>
          <br />
          <details>
            <summary>Revenge</summary>
            <a style={styledLink} onClick={toggleShow} href="#RevengeMode">
              Revenge Mode
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#TagsAndRevengeGain"
            >
              Tag System & Revenge Gain
            </a>
          </details>
          <br />
          <details>
            <summary>Team Mode Mechanics</summary>
            <a style={styledLink} onClick={toggleShow} href="#FriendlyFire">
              Friendly Fire Damage Reduction
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Feats">
              Feats
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Perks">
              Perks
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Executions">
              Executions
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#RespawnsAndRevives"
            >
              Respawn Times & Revive Mechanics
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Breaking">
              Breaking
            </a>
          </details>
          <br />
          <details>
            <summary>Map Features</summary>
            <a style={styledLink} onClick={toggleShow} href="#LedgesAndPlunges">
              Ledges & Plunge Attacks
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#LaddersAndZiplines"
            >
              Ladders and Zipline Ropes
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Hazards">
              Traps and Environmental Hazards
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Ballista">
              Ballista
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Boosts">
              Boosts
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Offerings">
              Offerings
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Soldiers">
              Soldiers (Minions)
            </a>
          </details>
          <br />
          <details>
            <summary>Renown </summary>
            <a style={styledLink} onClick={toggleShow} href="#RenownLevelsInfo">
              Renown Level Info
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#RenownLevelValues"
            >
              Renown Level Values for all Gamemodes
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#Killstreaks">
              Kill Streaks
            </a>
          </details>
          <br />
          <details>
            <summary>Duels & Brawls</summary>
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#DuelAndBrawlGamemodeInfo"
            >
              Duel & Brawl Gamemode Info
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#DuelAndBrawlRenownSources"
            >
              Renown Sources (Duel and Brawl)
            </a>
          </details>
          <br />
          <details>
            <summary>Dominion</summary>
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#DominionGamemodeInfo"
            >
              Dominion Gamemode Info
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#DominionRenownSources"
            >
              Renown Sources (Dominion)
            </a>
          </details>
          <br />
          <details>
            <summary>Breach</summary>
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#BreachGamemodeInfo"
            >
              Breach Gamemode Info
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#BreachBoostsAndBuffs"
            >
              Breach Boosts and Buffs
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#BreachOfficers">
              Breach Officers
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#BreachGuardian">
              Breach Guardian
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#BreachCommander">
              Breach Commander
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#BreachRenownSources"
            >
              {" "}
              Breach Renown Sources
            </a>
          </details>
          <br />
          <details>
            <summary>Deathmatch</summary>
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#DeathmatchGamemodeInfo"
            >
              Deathmatch Gamemode Info
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#DeathmatchBoosts">
              Deathmatch Boosts
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#EliminationRenownSources"
            >
              Renown Sources (Elimination)
            </a>
            <br />
            <a style={styledLink} onClick={toggleShow} href="#SkirmishCaptains">
              Skirmish Captains
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#SkirmishShieldCaptains"
            >
              Skirmish Shield Captains
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#SkirmishRenownSources"
            >
              Renown Sources (Skirmish)
            </a>
          </details>
          <br />
          <details>
            <summary>Tribute</summary>
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#TributeGamemodeInfo"
            >
              Tribute Gamemode Info & Shrine Bonuses
            </a>
            <br />
            <a
              style={styledLink}
              onClick={toggleShow}
              href="#TributeRenownSources"
            >
              Renown Sources (Tribute)
            </a>
          </details>
          <br />
          <br />
          <br />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const Information = () => {
  return (
    <div id="top">
      <NavPane placement={"start"} />
      <center>
        <div id="AttackRecoveriesChainLinks" />
        <Title text="Attack Recoveries & Chain Links" />
        <div style={styledInfo}>
          <p className="lead">
            Attack Recoveries vary between Heroes and the designated move, but
            all of them have three different timings: Recovery to Block(Guard
            Switch), Recovery to Dodge and Recovery to Guardbreak/Attack.
            <br />
            <br />
            These values will always be noted in this order in the infohub, and
            in general, with the community.
            <br />
            <br />
            A Chain Link refers to the timing window between linkable attacks in
            a combo. Chain Links essentially function as a Recovery Cancel, as
            they allow a Hero to bypass the Recoveries.
            <br />
            <br />
            The default minimum chain links speed seems to be 200ms, but they
            can be as fast as 100ms or as slow as 366ms in some cases. Most
            chain links can also be extended beyond the minimum link by delaying
            the input, and this is known as the "delay window."
          </p>
        </div>
        <div id="AttackGBVulnerability" />
        <Title text="Attack Guard Break Vulnerability" />
        <div style={styledInfo}>
          <p className="lead">
            All heavy opener attacks have 433ms GB vulnerability.
            <br />
            <br />
            All chain attacks have 100ms GB vulnerability.
            <br />
            <br />
            All Out of Stamina heavies have 600ms GB vulnerability.
            <br />
            <br />
            For other attacks, GB vulnerability varies with the attack speed:
            <br />
            0-700ms attacks - 0-100ms Guard Break vulnerability
            <br />
            800-1000ms attacks - 0-400ms Guard Break vulnerability
            <br />
            1100-1300ms attacks - 0-600ms Guard Break vulnerability
            <br />
            1400ms+ attacks - 0-800ms Guard Break vulnerability
            <br />
            <br />
            Some attacks do not follow these rules, and have different GB
            vulnerabilites, for example: most forward dodge heavies have 100ms
            Guard Break vulnerability.
          </p>
        </div>
        <div id="GuardbreakAndThrows" />
        <Title text="Guard Break & Throws" />
        <div style={styledInfo}>
          <p className="lead">
            Guardbreaks (GB) are 400ms, except when buffered after a feint as
            they become 300ms. Performing a Guard Break costs 15 Stamina.
            <br />
            <br />
            Counter guard break (CGB) window is 300ms (600-700ms reaction time
            dependent on which GB was used). CGB window starts upon landing.
            <br />
            <br />
            Guardbreak stun time is 800ms, during which
            the target can be attacked or thrown. If an attack is performed, the
            stun will be extended to allow for a 800ms window for punishes; this
            allows GB punishes to be delayable and not only possible when
            buffered.
            <br />
            <br />
            Guardbreak Bounce Recovery, 1000ms (when you try to gb and you
            bounce off). Guardbreak Miss recovery is 1000ms to guard switch, and
            900ms to dodge or GB again.
            <br />
            <br />
            Guardbreak Throws vary in duration and distance depending on the
            Hero, however they will all cost 17 Stamina and inflict 20 Stamina
            damage to the target.
            <br />
            <br />
            Guardbreak recoveries vary from move to move, but tend to be either 433ms 
            (if the move is a heavy), or 100ms, if the move is a light/zone, there are 
            notable exceptions which are on the notes of attacks, particularly for feintable 
            zone attacks (some are 100ms, some are 433ms). Revenge mode also ignores the vulnerability, 
            allowing a player in revenge to get a confirmed guardbreak at any stage of an attack.
          </p>
        </div>
        <div id="FrameAdvantage" />
        <Title text="Frame Advantage & Disadvantage" />
        <div style={styledInfo}>
          <p className="lead">
            The difference in time between an attacker recovering from their
            attack, and an opponent recovering from the hit/blockstun of that
            attack, is known as the frame advantage, and determines which player
            can attack first after that interaction.
            <br />
            <br />
            Basic light attacks have a longer recovery to attack than the
            hitstun they cause, thus are "frame disadvantaged" or "minus" on
            hit. Heavy finisher attacks have a shorter recovery to attack than
            the hitstun they cause, thus are "frame advantaged" or "plus" on
            hit. 
            <br />
            <br />			
			When both characters recover at the same time, such as after 
			Black Prior's Bulwark Counter, they are "neutral," where if both buffer
            attacks of the same speed, they will hit at the same time.
            <br />
            <br />
			Another common frame advantage situation is after a countered guard break. 
			The player who countered the guard break will have +33ms of frame advantage,
			allowing a buffered light attack to land before their opponent's buffered 
			light attack, and also allowing a buffered heavy attack to avoid being 
			caught by the opponent's next guard break.
            <br />
            <br />
            Because hitstun and blockstun have different durations and recoveries, 
			frame advantage can differ on hit vs on block. For example, 
			many heavy finishers with medium hit reactions are frame advantaged on hit, 
			and frame neutral on block. Non-standard moves can have frame advantages 
			that do not follow this pattern. 
          </p>
        </div>
        <div id="Feints" />
        <Title text="Feints" />
        <div style={styledInfo}>
          <p className="lead">
            Hard Feints (By pressing the feint button) all occur 400ms before
            the attack lands, with some exceptions. Exceptions include
            Charged/Slow Bashes, which can be feinted as late as 300ms before
            the move lands (133ms for Warmonger's Fully Charged Bash).
            <br />
            <br />
            Feints have 200ms recovery where you are vulnerable to guard breaks,
            and before you can attack, dodge, or parry.
            <br />
            <br />
            If you buffer a guard change during a feint, it is performed
            instantly after without the normal 100ms guard change animation.
            <br />
            <br />
            All hard feints cost 10 Stamina to perform. Feintable Zones follow
            the same rule unless noted otherwise.
          </p>
        </div>
        <div id="SoftFeints" />
        <Title text="Soft Feints" />
        <div style={styledInfo}>
          <p className="lead">
            Soft Feints are Hero specific Feint maneuvers that allows the Hero
            to switch to another attack, or a unique attack, without incurring a
            Feint cost (unless noted otherwise) or having the mandatory 200ms
            Feint recovery.
            <br />
            <br />
            Soft feint windows can vary by occuring earlier into a move's
            start-up, but cannot occur any later than the Hard Feint timing.
          </p>
        </div>
        <div id="Hyperarmor" />
        <Title text="Hyper Armor & Super Armor" />
        <div style={styledInfo}>
          <p className="lead">
            Hyper Armor, also known as Uninterruptible Stance, is a property
            given to certain actions that prevents a unit from flinching upon
            taking damage. Hyper Armor is available on all Guardbreak throws,
            along with being present on some Heroes as either part of their
            moveset or as a Feat.
            <br />
            <br />
            Super Armor is similar to Hyper Armor, except it also makes the unit
            immune to flinching from Bash moves. Super Armor is not present
            naturally on a Hero's moveset, but can be found as part of the Third
            Hitstun rule noted below, during the start-up of attacks when in
            Revenge mode, or when a Hero is given total damage and hitstun
            immunity when Respawning or being Revived.
          </p>
        </div>
        <div id="Parrying" />
        <Title text="Parry" />
        <div style={styledInfo}>
          <p className="lead">
            Attacks that are locked onto you can be parried by pressing heavy
            attack during the Parry window. This window starts 300ms before an
            attack connects (Just past feint window), and ends 100ms before an
            attack connects.
            <br />
            <br />
            Therefore 500ms attack's parry window looks like this:
            <br />
            <br />
            - - P P -
            <br />
            <br />
            Each " - " equals 100ms, P = Parry Window Active
            <br />
            <br />
            Stagger for having your Lights Parried is 900ms, whilst the stagger
            for having your Heavies Parried is 600ms. Unfeintable side dodge 
			attacks also grant a light parry punish when parried.
            <br />
            <br />
            Parry animation lasts for 800ms and whilst you cannot dodge during
            it, any other attacks that hit during that time will be parried
            automatically, extending the parry animation.
            <br />
            <br />
            Parry animation can be cancelled if the Hero has a Parry Riposte, or
            if extended by a multi-parry.
          </p>
        </div>
        <div id="Dodges" />
        <Title text="Dodge I-Frames & Recovery" />
        <div style={styledInfo}>
          <p className="lead">
            Invulnerability frames, or "i-frames", are windows when your
            character cannot be hit by incoming attacks (other than undodgeable
            attacks that are locked onto you). I-frames are present on side and
            back dodges, and on some dodge attacks and stances.
            <br />
            <br />
            Dodge i-frames start after 166ms and last until 300ms. Other moves
            that have i-frames have varying amouts.
            <br />
            <br />
			Most characters lose their guard completely during a dodge, regardless 
			of direction. However, characters that have a Deflect, or a Superior 
			Block Dodge, will gain a special guard state in a direction dependent 
			on the dodge direction, for a 200ms window (see sections below). 
			All characters lose guard throughout a back dodge.
			<br />
            <br />
            During a dodge, you cannot CGB. All character's side dodge
            recoveries are 633ms, and back dodge recoveries are 800ms - with the
            exception of Highlander in offensive stance. Front dodge recoveries
            differ between Heroes and are noted on the Hero's respective page.
            <br />
            <br />
            These recoveries apply to all actions except doing another dodge,
            which takes an extra 300ms (eg. 1100ms on Back dodge).
          </p>
        </div>
        <div id="Rolls" />
        <Title text="Rolls" />
        <div style={styledInfo}>
          <p className="lead">
            A Roll can be initiated by tapping dodge while unlocked, unlocking
            within 200ms of starting a dodge, sprinting to unlock within 400ms
            of starting a dodge, or double tapping dodge within 500ms of
            starting an in-lock backdodge
            <br />
            <br />
            On all Heroes, Rolling costs 50 Stamina, is 5m in distance and has a
            recovery of 1400ms. Dodge-rolls have 333ms GB vulnerability at the
            start of the roll portion.
          </p>
        </div>
        <div id="Deflects" />
        <Title text="Deflect" />
        <div style={styledInfo}>
          <p className="lead">
            All Assassins and Shaolin have deflects.
            <br />
            The input window is identical between a Parry and a Deflect, except
            for the fact that I-frames can lessen the Deflect window (see
            below).
            <br />
            <br />
            Deflects have 100ms start-up, and 200ms active frames.
            <br />
            <br />
            Side deflects are affected by i-frames. Normally the deflect window
            will be overriden when dodge i-frames start, giving a 67ms deflect
            window. Undodgeables ignore all i-frames, so you have the full 200ms
            window to deflect them. Like blocking and parrying, deflecting will
            activate an all block that lasts 500ms and will automatically
            deflect other incoming attacks. This window can be cancelled with a
            deflect follow-up attack, or extended when deflecting another
            attack, but you cannot dodge or parry during it. 
			<br />
            <br />
			After having an attack deflected, an opponent may continue chaining, 
			even if the attack would normally be prevented from chaining after 
			being blocked (eg. a light attack). This can allow characters with 
			Hyper Armor chain attacks to trade with a deflect follow-up attack, 
			unless that follow-up can interrupt Hyper Armor.  
            <br />
            <br />
            The deflect window of a Hero's Dodge looks like this:
            <br />
            <br />
            S D D - - -
            <br />
            <br />
            Each " - " is 100ms, D = Deflect Window Active, S = Deflect Startup
          </p>
        </div>
        <div id="BlockDodge" />
        <Title text="Blocking in the Dodge State" />
        <div style={styledInfo}>
          <p className="lead">
            Some Heroes have Superior Block Dodges, where their guard will move in the
            same direction of their dodge.
            <br />
            <br />
            Superior Block Dodges function similarly to Deflects, with 100ms start-up 
			and 200ms active frames, and normally allow a follow-up attack. Unlike 
			a Deflect, using a Superior Block Dodge against an incoming attack 
			will prevent the opponent from chaining (see below), so it can be safely 
			used against attacks which can chain into Hyper Armor. However, unlike 
			deflects, the all-block from a Superior Block Dodge cannot be extended 
			by blocking another incoming attack.
          </p>
        </div>
        <div id="Blocking" />
        <Title text="Guard Switch & Block/Superior Block" />
        <div style={styledInfo}>
          <p className="lead">
            Guardswitch takes 100ms on all Heroes. As of Season 10, all
            characters can attack, dodge, and GB immdiately after guardswitch
            (ie.100ms)
            <br />
            <br />
            Parrying & counter guardbreaking ignores guardswitch time &
            post-guardswitch recovery. Blocking ignores post-guardswitch
            recovery.
            <br />
            <br />
            Blocks can allow an attack chain to continue, or can prevent attack
            chaining, and this is known as a "superior block". Regular blocking
            will superior block normal light attacks, unless they have the
            enhanced property. Some special moves will superior block any
            blockable attacks (except zone attacks) preventing them chaining.
            <br />
            <br />
            If an attack can chain after being blocked, it will also do 18% of
            its base damage as chip damage. Chip damage cannot kill a character.
            <br />
            <br />
            Upon Blocking, your Hero receives all-block, and much like Parrying,
            this allows you to block multiple attacks in that window, each of
            which will extend the all-block. On superior blocks this autoblock
            is 400ms, but it is 600ms on regular blocks. (See Hit/Block stun in
            the next segment). During this all block, you cannot dodge, but you
            can parry another incomming attack. Attacks that have superior block 
			property during the attack (eg. crushing counter light on Highlander)
			do not gain this all block after superior blocking an attack.
          </p>
        </div>
        <div id="Shields" />
        <Title text="Shields" />
        <div style={styledInfo}>
          <p className="lead">
            Shields received by a Hero are temporary Health received through
            Feats, Perks or Boosts.
            <br />
            <br />
            They do not stack and cannot exceed max health, but do benefit from
            Damage Reduction and are not counted for the "Critical Health"
            prerequisite.
            <br />
            <br />
            The only time Shields are ignored is from Fall Damage (see 'Ledges &
            Plunge Attacks' segment below for details).
          </p>
        </div>
        <div id="OutOfStamina" />
        <Title text="Out of Stamina" />
        <div style={styledInfo}>
          <p className="lead">
            When your character is out of stamina (OOS) you cannot perform
            attacks other than basic lights and heavies, cannot feint, roll, or
            sprint.
            <br />
            <br />
            You will be unbalanced if an attack is parried or you are thrown,
            you will be staggered for longer if hit and not locked on, and your
            attacks become slower and have a 400ms parry window.
          </p>
        </div>
        <div id="OutOfLock" />
        <Title text="Out-of-Lock" />
        <div style={styledInfo}>
          <p className="lead">
            Out-of-Lock (also known as OoL) refers to the state outside of
            combat, when a Hero is allowed to sprint freely.
            <br />
            Being in OoL is disadvantageous for being in a fight as you cannot
            do the following: block or parry; choose attack direction; feint; or
            initiate special attacks that require multiple inputs such as dodge
            attacks or soft-feints.
            <br />
            <br />
            As shown in the stun chart in the 'Hit, Block & Superior Block Stun'
            section, Hitstun is also longer when OoL, which leads to greater
            punishes if one remains OoL, discouraging exiting Guard Mode into
            OoL. Exiting guard also has a 300ms recovery before you can take any
            other action, such as roll, re-lock, or start sprinting.
            <br />
            <br />
            Being out of stamina and OoL increases the amount of stagger an
            attack deals to you.
          </p>
        </div>
        <div id="Staggers" />
        <Title text="Collision Stagger/Knockdown" />
        <div style={styledInfo}>
          <p className="lead">
            Stagger after being thrown into a wall/another player is 1200ms
            <br />
            <br />
            Knock down duration when being thrown to the ground (Either when OOS
            or when opponent is in revenge) is 2500ms.
            <br />
            <br />
            Each bash has its own stagger duration.
          </p>
        </div>
        <div id="HitReactions" />
        <Title text="Hit, Block & Superior Block Stun" />
        <div style={styledInfo}>
          <p className="lead">
            Hit and Block stun refers to the stagger a Hero takes when they are
            hit by an attack or when they block an attack. During this time, the
            Hero cannot take any action other than buffer a guard switch, but
            GBs will bounce off them. There are varying recovery times for
            certain actions depending on Hit Reaction level of the attack that
            caused the hit/block stun. Normal attacks can have 3 levels of hit
            reaction - light, medium, and heavy. Each bash has its own stagger
            duration.
            <br />
            <br />
            The table below gives hit/block stun values in milliseconds. Bolded
            values have all-block for the duration of the block (see more in the
            "Guard Switch & Block/Superior Block" section above).
            <br />
            <br />
            As noted in the "Hitstun Stacking" rule section below, hit reactions
            cannot be applied more than 3 times without freeing the player."
          </p>
          <Table className="text-center" striped bordered  responsive="sm">
            <tbody>
              <tr>
                <th>Hit Reaction Level -{">"}</th>
                <td colSpan={3}>Low</td>
                <td colSpan={3}>Medium</td>
                <td colSpan={3}>High</td>
              </tr>
              <tr>
                <th>Recovery to -{">"}</th>
                <td>Block</td>
                <td>Dodge</td>
                <td>Attack</td>
                <td>Block</td>
                <td>Dodge</td>
                <td>Attack</td>
                <td>Block</td>
                <td>Dodge</td>
                <td>Attack</td>
              </tr>
              <tr>
                <td>On Hit</td>
                <td>600</td>
                <td>500</td>
                <td>600</td>
                <td>600</td>
                <td>700</td>
                <td>800</td>
                <td>600</td>
                <td>900</td>
                <td>1000</td>
              </tr>
              <tr>
                <td>On Block</td>
                <th>600</th>
                <td>600</td>
                <td>600</td>
                <th>600</th>
                <td>700</td>
                <td>700</td>
                <th>600</th>
                <td>800</td>
                <td>800</td>
              </tr>
              <tr>
                <td>On Superior Block</td>
                <th>400</th>
                <td>400</td>
                <td>400</td>
                <th>400</th>
                <td>400</td>
                <td>400</td>
                <th>400</th>
                <td>400</td>
                <td>400</td>
              </tr>
              <tr>
                <td>On Out-Of-Lock Hit</td>
                <td>700</td>
                <td>600</td>
                <td>600</td>
                <td>900</td>
                <td>800</td>
                <td>800</td>
                <td>1100</td>
                <td>1000</td>
                <td>1000</td>
              </tr>
              <tr>
                <td>On Out-Of-Lock and Out-Of-Stamina Hit</td>
                <td>900</td>
                <td>900</td>
                <td>600</td>
                <td>1100</td>
                <td>1100</td>
                <td>1100</td>
                <td>1300</td>
                <td>1300</td>
                <td>1000</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div id="DamageAndHitstunRules" />
        <Title text="Damage Reduction & 'Hitstun Stacking' rules" />
        <div style={styledInfo}>
          <p className="lead">
            Receiving hitstun from either an enemy attack or from a wallsplat
            will cause incoming attacks to deal reduced damage: 25% damage
            reduction on the second hit, and 75% damage reduction on a third
            hit. Some guaranteed multi-hits (eg. Berserker zone) ignore 2nd hit
            damage reduction.
            <br />
            <br />
            After the 3rd hit (including wallsplats) landing in one hitstun,
            your Hero will briefly gain Super Armor as you return to neutral,
            allowing you to immediately block and ignore incoming attack
            hitstun. This prevents a Hero from being locked in Stagger from
            consecutive attacks in a gank scenario. After the Super Armor is
            applied, all damage reduction from the Hitstun and the Third Hitstun
            rule counter will reset.
            <br />
            <br />
            Currently, Pinning attacks partially override these rules - they
            will be damage reduced if landed in hitstun, and will bounce off 3rd
            hitstun, if landed as the 2nd hit on a hitstunned opponent, they
            will still pin the opponent, and "reset" hitstun. A subsequent hit
            on a pinned opponent will not be damage reduced, and counts as an
            attack in neutral for the purposes of hitstun.
          </p>
        </div>
        <div id="RevengeMode" />
        <Title text="Revenge Mode" />
        <div style={styledInfo}>
          <p className="lead">
            When the Revenge meter is full, it is possible to activate Revenge
            Mode either from neutral or during the recovery of an attack move or
            hit reaction. When pinned, you can activate Revenge once damage is
            applied.
            <br />
            <br />
            On activation, your Hero will perform an Auto-Parry. Auto-Parry has
            100ms of startup, 800ms of active frames that provides an all-Guard
            that knocks down enemies who attack you during the active frames.
            Auto-Parry frames are cancelled when taking any action except
            Guard-switch.
            <br />
            <br />
            Revenge Mode lasts 8 seconds after activation and grants the
            following: a 120HP Overshield, a 30% Attack Boost, Enhanced Lights
            and instant Super Armor + GB Invulnerability on Move/GB start-ups; GB
            Throws can still be interrupted by Bashes or Feats.
            <br />
            <br />
            All Parries, including Auto-Parry, Guardbreak Throws and certain
            Throwing maneuvers (eg. Nuxia's Finisher Heavies) during Revenge
            will knock enemy Heroes down - Guardbreaks during this mode also
            catch enemies regardless of what stage of their attack they're in.
          </p>
        </div>
        <div id="TagsAndRevengeGain" />
        <Title text="Tag System & Revenge Gain" />
        <div style={styledInfo}>
          <p className="lead">
            Revenge meter (total Revenge score needed to activate revenge) = (10
            + Hero Base Max Health)*0.6
            <br />
            <br />
            Base Health excludes Feats/Perks that increase Health
            <br />
            <br />
            Base Revenge score per attack = Raw Attack damage x Contact
            multiplier
            <br />
            <br />
            Raw Attack damage: The amount of health damage the move deals before
            damage buffs and debuffs, not including bleed damage.
            <br />
            <br />
            GBs/Throws/CGBs/failed GBs, and all melee attacks that deal no
            damage, or deal instantaneous damage (eg. Glad Toe Stab) count as a
            30 damage attack for the purposes of revenge. Some pinning moves add
            revenge twice - flat 30 when they impact, and an amount based on
            their damage, when that damage occurs. These are Shaman Bite,
            Shugoki Demon Embrace, and Nuxia's Traps. Gladiator's Skewer also
            counts as a 30 damage attack for revenge purposes.
            <br />
            <br />
            Contact multiplier:
            <br />
            Avoided through I-frames (Dodge/Hidden Stance): 0.25
            <br />
            Hit/Blocked/Deflect: 1.0
            <br />
            Parried: 1.2
            <br />
            <br />
            Base Revenge score x Group Fight multiplier = Final Revenge Score
            fed
            <br />
            <br />
            Group Fight multipliers:
            <br />
            1v1, 2v2, 3v3, 4v4 = 0.0
            <br />
            1v2, 2v3, 3v4 = 0.6
            <br />
            1v3, 2v4 = 0.8
            <br />
            1v4 = 0.9
            <br />
            <br />
            Group fight multiplier is affected by how many Tags you and your
            opponent have. Moves and Feats that deal health/stamina damage,
            guardbreaks (GBs/Throws/CGBs/failed GBs), attacks that are
            blocked/superior blocked, deflected, or dodged during either
            Hidden/Sifu Stance's I-frames or a dodge's 133ms of I-frames (but
            NOT a dodge attack's I-frames) will apply a Tag for 5 seconds; Feats
            that only debuff the target will not apply a Tag.
            <br />
            <br />
            Revenge meter fills only if you have more Tags than the opponent
            attacking you (shown via how equal fights have 0.0 Group Fight
            multiplier) while in-Lock. You won't gain any Revenge while
            out-of-Lock, though you can apply/gain Tags. An opponent's revenge
            meter flashes if your attacks will fill their Revenge meter.
            <br />
            <br />
            Damage over time (fire, bleed) don't fill the Revenge meter, nor
            does it refresh Tags. Revenge meter will remain filled so long as
            you are locked onto an enemy Hero, even if you have lost all Tags.
            The meter depletes after 5 seconds of not being locked onto an enemy
            Hero at a rate of ~15.5pts/sec.
          </p>
        </div>
        <div id="FriendlyFire" />
        <Title text="Friendly Fire Damage Reduction" />
        <div style={styledInfo}>
          <p className="lead">
            Hitting an ally with an attack deals 20% of normal damage to them.
            <br />
            They will be affected by hit/blockstun, but cannot be ledged by
            friendly attacks.
          </p>
        </div>
        <div id="Feats" />
        <Title text="Feats" />
        <div style={styledInfo}>
          <p className="lead">
            Feats: Heroes will have up to four Feats available in gamemodes that
            allow their use, divided between tiers. Feats become available as
            the Hero gains Renown (see Renown Levels info above), first gaining
            access to their Tier 1 Feat, usually the most basic but weakest Feat
            available to them, and eventually receiving their strongest Feat,
            their Tier 4 Feat, if they perform well in a match. Each Hero can
            select one of three Feats to use per Tier outside of a game, in the
            customization menu, but also during the Hero select menu.
          </p>
        </div>
        <div id="Perks" />
        <Title text="Perks" />
        <div style={styledInfo}>
          <p className="lead">
            Perks: Heroes will have access to Perks when Feats are available;
            Perks are determined by the Gear a Hero has equipped. Each Hero has
            access to 7 Perks total, which is designated to the Hero from two of
            the three Perk pools (Offense, Defense, Support). Starting at three
            Perks available at Reputation 0, a Hero gains access to their higher
            tier Perks as they gain higher rarity of Gear, at Reputation 1, 3, 5
            and 7. Perks are intended to being unique passives that are slightly
            weaker than a Tier 1 Feat.
          </p>
        </div>
        <div id="Executions" />
        <Title text="Executions" />
        <div style={styledInfo}>
          <p className="lead">
            Execution: Upon landing a killing blow with a Heavy Attack, Heavy
            Dodge Attack, or any other noted moves, the player can choose to
            Execute the target, removing their ability to be Revived, thus
            forcing a Respawn, and healing the executioner. The execution prompt
            is minimum 700ms, and can be delayed to 1500ms. Executions locks the
            Hero in an animation for some seconds, thus is not always optimal to
            initiate as they can leave one vulnerable.
          </p>
        </div>
        <div id="RespawnsAndRevives" />
        <Title text="Respawn Times & Revive Mechanics" />
        <div style={styledInfo}>
          <p className="lead">
            All gamemodes (except for Duels) have access to the Revive mechanic.
            Many of these gamemodes also have access to their own designated
            Respawn systems; Duels, Brawls and Elimination do not have a Respawn
            system.
            <br />
            <br />
            Reviving a Hero in most gamemodes takes 4 seconds by default, which
            can be decreased by the Speed Revive feat down to 2.7 seconds.
            <br />
            <br />
            Upon being Revived, the Revived Hero gains 50% HP (75% HP if the
            Reviver has the Rising Dawn perk) while going through a Revive
            animation for 2.5 seconds, where they are immune to damage and have
            Super Armor, but cannot take action, benefit from healing zones or
            contest a Capture Zone for the duration.
            <br />
            <br />
            In all gamemodes with Revives available, a Hero can be revived by an
            ally as long as 60 seconds has not passed since they've died. If the
            downed Hero has been Executed, knocked out of bounds, or killed by
            traps/spikes, they cannot be revived and have to Respawn instead.
            Players can Revive an ally as many times as they want in all
            gamemodes except Elimination & Brawls. In these two gamemodes, a
            player can only be Revived once per Round; their next death after
            being Revived will result in them being marked as ""executed"" until
            the next Round.
            <br />
            <br />
            The Respawn system allows a player to reenter the gamemode without
            needing to be Revived. Each gamemode with the Respawn system have
            different Respawn timer values, which affect how soon a player can
            voluntarily choose to Respawn (a player auto-Respawns after 60
            seconds). Below is a list of each gamemode's Respawn timer values:
            <br />
            <br />
            <b>Dominion</b>: 12s
            <br />
            <b>Tribute</b>: 10s (+5s for every active Shrine for your team)
            <br />
            <b>Skirmish</b>: 5s
            <br />
            <b>Breach</b>: 20s
            <br />
            <br />
            For most gamemodes, being Executed extends the Respawn timer by +3.
          </p>
        </div>
        <div id="Breaking" />
        <Title text="Breaking" />
        <div style={styledInfo}>
          <p className="lead">
            "Breaking" is a state a team is placed in when the opposing team
            completes the gamemode's objective, causing your team to have no
            more Respawns. Usually when placed into Breaking, your team will
            also be placed on a time limit.
            <br />
            <br />
            Depending on the gamemode, the purpose of "Breaking" is either as a
            last stand or a warning requiring the Breaking team to make a
            comeback by undoing the Breaking status. Below is a list of
            gamemodes and their Breaking condition(s).
            <br />
            <br />
            <b>Dominion</b>
            <br />
            Enemy Team reaches 1000 Points (including soft points).
            <br />
            Breaking can be undone if the Enemy Team goes under 1000 points.
            <br />
            <br />
            <b>Skirmish</b>
            <br />
            Enemy Team reaches 1000 Points.
            <br />
            <br />
            <b>Tribute</b>
            <br />
            Enemy Team has all three Offerings in their Shrines.
            <br />
            Both teams can still Respawn during Breaking.
            <br />
            <br />
            <b>Breach</b>
            <br />
            Only attackers can break, occurs when attacking team has no more
            respawn tickets.
          </p>
        </div>
        <div id="LedgesAndPlunges" />
        <Title text="Ledges & Plunge Attacks" />
        <div style={styledInfo}>
          <p className="lead">
            Ledges that lead to out-of-bounds locations are always lethal.
            Players can be pushed off a Ledge by enemy Bashes/Sweeps/Throws,
            enemy Attacks with medium/heavy hitstun, allied feats, or by being
            bumped by staggered allies or opponents.
            <br />
            <br />
            A player can also choose to jump off ledges that are over
            transversable grounds in order to get to lower grounds. Some ledges
            require voluntary action to jump down (either with the dodge or an
            attack input), while other shorter ledges can be walked off. If the
            ledge is high enough, the player will receive fall damage based on
            the height of the fall. Fall damage deals % health damage, meaning
            Heroes with more health do not survive higher falls.
            <br />
            <br />
            Ledges under ~2 meters tall will deal no damage, while Ledges over
            ~6 meters tall will be lethal to any Hero without a Shield.
            <br />
            <br />
            If the player has a Shield when they fall, the Shield will take
            damage first before their Health. However, if a player is knocked
            off a ledge involuntarily (for example, by an enemy's bash), they
            will be dealt double the fall damage, with the fall damage ignoring
            all Shields if the fall would be lethal without Shields.
            <br />
            <br />
            Upon performing an attack input at a ledge or while leaping off a
            ledge, the player will perform a Plunge Attack, causing them to make
            an attempt to attack any enemy unit below with an Unblockable,
            Unparriable attack. If there are no enemy units below, the Plunge
            Attack will function much like jumping off a ledge, dealing damage
            to the player for jumping off a high enough ledge.
            <br />
            <br />
            However, if the player lands a blow on an enemy unit, they will deal
            2000 damage (regardless of height or Hero) and not take any fall
            damage.
          </p>
        </div>
        <div id="LaddersAndZiplines" />
        <Title text="Ladders and Zipline Ropes" />
        <div style={styledInfo}>
          <p className="lead">
            Ladders and Ziplines act as shortcuts for players to avoid
            roundabout paths such as slopes, stairs or ledges, but are
            bottlenecked or one-way use respectively. If hit while on either,
            you will be knocked off and fall.
            <br />
            <br />
            <u>
              <b>Ladders</b>
            </u>
            <br />
            Hopping on or off a Ladder has 500ms of forced action and stagger
            invulnerability. While on a Ladder, Stamina Regen is paused. Heroes
            can Slide down a Ladder with the Dodge input, which lasts a minimum
            of 300ms and costs 25 Stamina to activate. Sliding knocks off anyone
            who is in the way on the Ladder.
            <br />
            <br />
            Heroes can also opt to press an Attack input to punch upwards.
            Although it also costs 25 Stamina and doesn't deal damage, it will
            knock anyone directly above off if the punch connects, this has the
            potential to instantly kill them, or do a lot of damage.
            <br />
            <br />
            <u>
              <b>Zipline Ropes</b>
            </u>
            <br />
            Hopping on and off a Zipline takes roughly 500ms. Press Interact
            near the top of a Zipline to hop on, and at any point of the rope,
            as if at a Ledge, press the Dodge or an Attack input to drop off a
            Zipline. If a player drops off while over an out-of-bounds or hazard
            area, this will lead to environmental death, thus giving them the
            inability to be revived.
          </p>
        </div>
        <div id="Hazards" />
        <Title text="Traps and Environmental Hazards" />
        <div style={styledInfo}>
          <p className="lead">
            Some maps have access to "Traps" which can either be voluntarily or
            involuntarily triggered. All Traps are lethal, except for certain
            Trapdoors.
            <br />
            <br />
            Below is a list of all known <b>Traps</b> in the game and their
            effects:
            <br />
            <br />
            Trapdoor - Trapdoors typically are floors that can be tranversed
            over, unless they are triggered to open up. As mentioned above,
            Trapdoors can be non-lethal in a few cases.
            <br />
            Crushing Gate - On Sanctuary Bridge, Secluded Keep and Sentinel,
            there are special gates at archways that will crush anyone who
            stands under them when they are activated.
            <br />
            Cauldron - On Secluded Keep, there are cauldrons of hot lava that
            can be poured below to spell instant death on anyone below. They are
            also present in breach, but do much less damage to players - and is
            instead a tool to destroy the ram.
            <br />
            Spike Wheel - In some gamemodes, the Viking Village has a switch to
            unleash the Spike Wheel, which will roll through the village,
            crushing to death all in its way.
            <br />
            <br />
            Outside out-of-bounds ledges, most maps have other forms of
            Environmental Hazards that can be a danger to units. Much like
            traps, most of the hazards are lethal.
            <br />
            <br />
            Below is a list of all known <b>Environmental Hazards</b> in the
            game and their effects:
            <br />
            <br />
            Pits - Similar to out-of-bounds ledges, Pits are holes or collection
            of water/lava within a map's borders that are lethal.
            <br />
            Wall Spikes - On some walls, there may be spikes or blades which
            instantly kill any unit pushed into them.
            <br />
            Fire Pits - Stacks of fire or open flames may be on a field, causing
            fire damage and stagger to anyone pushed into the flames.
            <br />
            Saw Blades - Present only on Shipyard, these are open saw blades
            that cut anyone pushed against them, dealing damage and stagger.
            <br />
            Workshop Hammers - Present only on Forge, active workshop hammers
            that will crush anyone who happens to stand under one of them as
            they come down.
            <br />
            Geysers - Present only on Cathedral, geysers will occassionally
            shoot out hot water, killing anyone who stands within. Otherwise,
            being pushed into an active one deals damage and stagger.
          </p>
        </div>
        <div id="Ballista" />
        <Title text="Ballista" />
        <div style={styledInfo}>
          <p className="lead">
            The Ballista is available on one map in Dominion (Sentinel) and on
            all Breach Maps. It is disabled or unavailable in all other modes.
            <br />
            <br />
            It has limited turn speed and view angle, deals 60 damage (deals
            100% friendly fire) to all units struck by its bolts, and takes 3
            seconds to reload each bolt. Mounting and dismounting the Ballista
            takes a second.
            <br />
            <br />
            While on the Ballista, the player will not have access to their
            minimap, nor can they use their Feats. If attacked while on the
            Ballista, players will receive doubled damage while also being
            knocked off the Ballista.
          </p>
        </div>
        <div id="Boosts" />
        <Title text="Boosts" />
        <div style={styledInfo}>
          <p className="lead">
            "In some gamemodes, Boosts (also known as ""Buffs"") may be
            available for taking by either side. Boosts will appear as floating
            teal symbols in a teal energy circle, which will take approximately
            2.5 uncontested seconds to be given to a player. If two or more
            allied players stand on the Boost, only the first person will
            collect the Boost.
            <br />
            <br />
            A Boost on cooldown will be visually grey. Cooldown progress is
            visible by two means: the greyed out energy circle will shrink in
            size, and once there is 30 seconds left on the cooldown, the Boost
            symbol will slowly rise to its original position. Once the Symbol
            reaches its original position and the circle is non-existant, the
            Boost will go off-cooldown.
            <br />
            <br />
            Boosts are available in both Deathmatch modes, Breach, the Arcade
            playlist and during some Special Event Gamemodes.
            <br />
            <br />
            Below is a list of the Boost types:
            <br />
            <b>Attack Boost</b>: A Boost that increases damage output
            <br />
            <b>Defense Boost</b>: A Boost that grants a defensive shield
            <br />
            <b>Healing Boost</b>: A Boost that heals Health overtime
            <br />
            <b>Speed Boost</b>: A Boost that increases movement speed
            <br />
            <b>Star Boost</b>: A Boost that grants access to all feats
          </p>
        </div>
        <div id="Offerings" />
        <Title text="Offerings" />
        <div style={styledInfo}>
          <p className="lead">
            Offerings, also known as "Banners" in Breach, is a staff-like object
            that players can pick up in Tribute and Breach. The goal with these
            Offerings is to place them into an allied Shrine to gain certain
            benefits for your team, or in the case of Tribute, win the gamemode.
            <br />
            <br />
            In either gamemode, it takes 1.5 seconds to pick up the Offering on
            the ground, and takes 3 seconds to place into a Shrine. Carrying a
            Shrine will apply a 25% Movement Speed debuff. While in Breach, an
            Offering placed into a Shrine is permanently consumed, in Tribute,
            enemy Heroes can take Offerings from a Shrine. Taking an Offering
            from a Shrine also takes 3 seconds, but can be modified based on
            what shrines are active (read more in the Tribute Gamemode Info
            section).
          </p>
        </div>
        <div id="Soldiers" />
        <Title text="Soldiers (Dominion & Skirmish)" />
        <div style={styledInfo}>
          <p className="lead">
            Soldiers will deal 2 damage per attack, attacking more frequently
            when there are at least three allied Soldiers in close proximity,
            while acting more cowardly (even retreating) when there are less
            than three allies nearby.
            <br />
            <br />
            Soldiers technically have only 6 health (one-shot on most attacks),
            they receive double damage from Heroes. This allows them to fight
            with enemy Soldiers and also be briefly bled by Bleeding attacks.
          </p>
        </div>
        <div id="RenownLevelsInfo" />
        <Title text="Renown Levels Info" />
        <div style={styledInfo}>
          <p className="lead">
            In all gamemodes with access to Feats (see more about Feats below),
            Heroes will gain access to each tier of Feats at designated Renown
            threshold levels. These thresholds are stated at the top of each
            designated column to the right. To reach these thresholds, a Hero
            must gain Renown by performing tasks in the gamemode.
            <br />
            <br />
            In Dominion and Skirmish, Renown gain depends on a Hero's designated
            class, changing which tasks awards what amount of Renown.
            <br />
            <br />
            Take note that Renown earned by the Hero is not equivalent to
            Points; all Heroes equally collect Points for their team.
          </p>
        </div>
        <div id="RenownLevelValues" />
        <Title text="Renown Level Values for all Gamemodes" />
        <div style={styledInfoTable}>
          <Table className="text-center" striped bordered  responsive="sm">
            <tbody>
              <tr>
                <td></td>
                <td colSpan={4}>Feat Renown Levels</td>
              </tr>
              <tr>
                <td>Gamemode</td>
                <td>Level 1</td>
                <td>Level 2</td>
                <td>Level 3</td>
                <td>Level 4</td>
              </tr>
              <tr>
                <td>Dominion</td>
                <td>50</td>
                <td>120</td>
                <td>210</td>
                <td>320</td>
              </tr>
              <tr>
                <td>Breach</td>
                <td>125</td>
                <td>300</td>
                <td>660</td>
                <td>1100</td>
              </tr>
              <tr>
                <td>Tribute</td>
                <td>60</td>
                <td>155</td>
                <td>265</td>
                <td>335</td>
              </tr>
              <tr>
                <td>Elimination</td>
                <td>20</td>
                <td>40</td>
                <td>90</td>
                <td>140</td>
              </tr>
              <tr>
                <td>Skirmish</td>
                <td>35</td>
                <td>75</td>
                <td>140</td>
                <td>220</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div id="Killstreaks" />
        <Title text="Kill Streaks" />
        <div style={styledInfoTable}>
          <Table className="text-center" striped bordered  responsive="sm">
            <tbody>
              <tr>
                <td>
                  Gamemode →<br />
                  Killstreak # ↓
                </td>
                <td>Dominion</td>
                <td>Elimination</td>
                <td>Skirmish</td>
                <td>Tribute</td>
              </tr>
              <tr>
                <td>Heroic (3 Kills)</td>
                <td>+8</td>
                <td>+6</td>
                <td>+10</td>
                <td>+8</td>
              </tr>
              <tr>
                <td>Bloodthirsty (4 Kills)</td>
                <td>+10</td>
                <td>+10</td>
                <td>+15</td>
                <td>+10</td>
              </tr>
              <tr>
                <td>Unstoppable (5 Kills)</td>
                <td>+14</td>
                <td>+14</td>
                <td>+20</td>
                <td>+14</td>
              </tr>
              <tr>
                <td>Legendary (6 Kills)</td>
                <td>+16</td>
                <td>+18</td>
                <td>+25</td>
                <td>+16</td>
              </tr>
              <tr>
                <td>Godlike (7 Kills)</td>
                <td>+20</td>
                <td>+22</td>
                <td>+30</td>
                <td>+20</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div id="DuelAndBrawlGamemodeInfo" />
        <Title text="Duel & Brawl Gamemode Info" />
        <div style={styledInfo}>
          <p className="lead">
            A round of Duels/Brawls lasts 5 minutes, with a match being a
            Best-of-5. Perks and Feats are not active in these gamemodes. If the
            timer runs out, winner is determined by whoever has more health, or
            in the case of a Brawl, who has more collective health.
            <br />
            <br />
            If a player decides to avoid combat by running away from an
            opponent, a Speed Debuff will be applied to them eventually, only
            repealed once the player re-engages in combat.
          </p>
        </div>
        <div id="DuelAndBrawlRenownSources" />
        <Title text="Renown Sources (Duel and Brawl)" />
        <div style={styledInfo}>
          <p className="lead">
            Take note, even though these two gamemodes have Renown scores
            granted to the player upon performing certain tasks, they have no
            effect on final scores, or even gameplay as Feats and Perks are not
            active in these gamemodes.
            <br />
            <br />
            <b>Hero Kill</b> - 10
            <br />
            <b>Assist</b> - 10
            <br />
            <b>Perfect</b> - 10
            <br />
            <b>Revive</b> - 10
          </p>
        </div>
        <div id="DominionGamemodeInfo" />
        <Title text="Dominion Gamemode Info" />
        <div style={styledInfo}>
          <p className="lead">
            Dominion is an Objective gamemode, where you contest two Side Points
            and the Midlane to gain points for your team in order to place the
            opposing team into Breaking. A match of Dominion lasts 10 minutes.
            <br />
            <br />
            To put the opposing team into Breaking, your team must collect 1000
            points. The Side Points and the Midlane each grant 100 soft points
            while generating hard points. Other tasks all provide hard points.
            <br />
            <br />
            Capturing Zones differ between Side Points and the Midlane. Side
            Points only requires 5.5 seconds of standing on an uncontested Zone
            (less with more allies).
            <br />
            <br />
            The Midlane though is only captured when 3 Soldiers reach the far
            side of the zone; Soldiers will reactively retreat when overwhelmed,
            though the Midlane remains captured as long as one allied Soldier
            stays at the far side.
            <br />
            <br />
            As of update 2.34.0, changes have been made to the Renown System to
            unify all Renown gain in Dominion between Hero classes.
          </p>
        </div>
        <div id="DominionRenownSources" />
        <Title text="Renown Sources (Dominion)" />
        <div style={styledInfo}>
          <p className="lead">
            Points earned by the Hero is noted beside the source name, while the
            definition of the bonus is listed underneath.
            <br />
            <br />
            <b>Hero Kill</b> - 20
            <br />
            Land the killing blow on an enemy Hero. Execution not required.
            <br />
            <br />
            <b>Soldier Kill</b> - 2
            <br />
            Kill an enemy Soldier. A Soldier killed by a Hero (allied or enemy)
            will grant a point to the Points pool that opposes the killed
            Soldier; a minion killing another will not.
            <br />
            Having control of the midpoint grants Points generation at a rate of
            90pts/min and 100 temporary Points, while causing more enemy
            Soldiers to spawn.
            <br />
            <br />
            <b>Assist</b> - 10
            <br />
            Assist an Ally in a Hero Kill. Done by either applying a Revenge Tag
            or Debuff to the target, or granting a buff to the Ally, right
            before the target is killed by your Ally.
            <br />
            The only time you don't get an assist is when the Revenge Tag you
            applied was from a bounced GB (GB on someone in the middle of being
            GB invulnerable).
            <br />
            <br />
            <b>Comeback</b> - 25
            <br />
            After dying 3+ times in a row to enemy Heroes without scoring a
            kill, kill someone.
            <br />
            <br />
            <b>Revenge</b> - 17
            <br />
            Kill an opponent that has killed you that you haven't killed since
            that death
            <br />
            <br />
            <b>Streak Breaker</b> - 25
            <br />
            Kill an opponent that has a streak (3+ kills in a row)
            <br />
            <br />
            <b>Reviving an Ally</b> - 20
            <br />
            Successfully revive an Ally. Check the "Respawn Times & Revive
            Mechanics" section for more info.
            <br />
            <br />
            <b>Capturing a Zone</b> - 10
            <br />
            Capturing a Zone, passively granting your team Points generation and
            100 temporary Points. One unboosted Zone grants 54pts/min.
            <br />
            <br />
            <b>Contesting a Zone</b> - 30/min
            <br />
            Be on an enemy or neutral Capture Zone with an enemy Hero. While a
            Zone is contested, teams do not receive Points generation from the
            Zone.
            <br />
            <br />
            <b>Defending a Zone</b> - 36/min
            <br />
            Be on an friendly Capture Zone with an enemy Hero. While a Zone is
            contested, teams do not receive Points generation from the Zone.
            <br />
            <br />
            <b>Boosting a Zone</b> - 42/min
            <br />
            Stand on a Friendly Capture Zone, healing ~10 HP per second and
            boosting Points generation from 54pts/min to 90pts/min. Also causes
            more enemy Soldiers to spawn in the midlane.
            <br />
            <br />
            <b>Team Rallied</b> - 30
            <br />
            Capture a Zone which rescues your team from Breaking by bringing the
            enemy team below 1000 total points.
          </p>
        </div>
        <div id="BreachGamemodeInfo" />
        <Title text="Breach Gamemode Info" />
        <div style={styledInfo}>
          <p className="lead">
            Breach is an Objective gamemode with assymetrical winning
            conditions.
            <br />
            <br />
            <b>Attackers:</b>
            <br />
            Breach both Gates and Kill the Defender's Commander
            <br />
            <br />
            <b>Defenders:</b>
            <br />
            Destroy the Ram before the Attackers break through the Second Gate.
            <br />
            During the 3rd Phase, reduce Attacker's Respawn Tickets to 0 (thus
            putting Attackers into Breaking) and kill all Attackers
            <br />
            Match Timer (30 minutes) reaches 0.
            <br />
            <br />
            While not mandatory to capture, Breach Side Points are an important
            asset to aid either side through the presence of Archers. All Side
            Points start out owned by Defenders, and take 2 seconds to be
            captured or recaptured.
            <br />
            <br />
            When Attackers have taken the Point, they will slowly fortify the
            Point over 30 seconds of holding the point, reduced to as little as
            15 seconds when Boosted; capture progress is saved even when
            recaptured by the Defenders.
            <br />
            <br />
            Once Fortified, the point cannot be retaken by the Defenders, and
            the next Side Point will become available for capture.
            <br />
            <br />
            <b>
              <u>Pikemen</u>
            </b>
            <br />
            Each Pikeman has 37 HP, and deals 8 damage per attack
            <br />
            Much like Dominion Soldiers, they attack more frequently when allies
            are nearby
            <br />
            <br />
            <b>
              <u>Archer</u>
            </b>
            <br />
            Deals 8 damage per shot (only targets Pikemen)
            <br />
            Much like Dominion Soldiers, they die in one hit Won't spawn if a
            Side Point is contested
            <b>
              <br />
              <br />
              <u>Cauldron Damage</u>
            </b>
            <br />
            750 to Ram (inner circle)
            <br />
            Insta-kill for Attacker Pikemen (inner/outer circle)
            <br />
            24 to Heroes (inner circle)
            <br />
            <br />
            <b>
              <u>Cauldron Timing</u>
            </b>
            <br />
            5 seconds to activate
            <br />
            30 second cooldown
            <br />
            <br />
            <b>
              <u>Ram Health</u>
            </b>
            <br />
            9000 HP
            <br />
            Depending on Ram Health at end of Phase 2, Attackers receive 6
            (critical health) to 22 (full health) Respawn tickets for Phase 3.
            <br />
            <br />
            <b>
              <u>Ram Charge Time</u>
            </b>
            <br />
            Takes 40 seconds to Ram the Gate once.
            <br />
            Can be as fast as 12 seconds if Boosted. 5 second cooldown after
            Ramming.
            <br />
            <br />
            <b>
              <u>Breach Banner</u>
            </b>
            <br />
            Spawns 3 minutes into a Phase, Respawns after 4 minutes of being
            consumed.
            <br />
            Slows holder's movement speed by 25% and highlights them to the
            enemy team.
            <br />
            <br />
            <b>
              <u>Banner effect on Gate/Ram</u>
            </b>
            <br />
            Gate: take one more extra hit from the Ram.
            <br />
            Ram: 750 Health Shield (ie. 1 Cauldron drop).
            <br />
            <br />
            Effects can only stack up to twice.
          </p>
        </div>
        <div id="BreachBoostsAndBuffs" />
        <Title text="Breach Boosts, Healing Zone & Guardian Buff" />
        <div style={styledInfo}>
          <p className="lead">
            The Attack, Shield and Movement Speed Boosts all spawn 45 seconds
            into the Phase when available, and respawns 3 minutes after being
            taken.
            <br />
            <br />
            <b>
              <u>Attack Boost</u>
            </b>
            <br />
            Grants +35% bonus damage. Lasts for 75 seconds or until repsawn
            (persists through death if revived).
            <br />
            <br />
            <b>
              <u>Shield Boost</u>
            </b>
            <br />
            Grants a shield equal to half of the Hero's max health, lasts
            indefinitely.
            <br />
            <br />
            <b>
              <u>Movement Speed Boost</u>
            </b>
            <br />
            Grants +45% bonus movement speed and 50% Stamina reduction on
            out-of-lock moves (chain attacks done out-of-lock only receive a 20%
            reduction).
            <br />
            Lasts for 75 seconds or until repsawn (persists through death if
            revived).
            <br />
            <br />
            <b>
              <u>Healing Zone</u>
            </b>
            <br />
            Heals 10 health per second when uncontested.
            <br />
            Takes 2 seconds (less with more allies) to capture for your team.
            <br />
            <br />
            <b>
              <u>Guardian Buff</u>
            </b>
            <br />
            Grants a shield equal to 100% of the Hero's max health, lasts
            indefinitely. <br />
            Activates Revenge mode instantly, lasting 8 seconds.
            <br />
            Also grants infinite Stamina, 10x damage to Pikemen and 50% damage
            reduction from Pikemen damage, for 2 minutes or until respawn
            (persists through death if revived).
          </p>
        </div>
        <div id="BreachOfficers" />
        <Title text="Breach Officers" />
        <div style={styledInfo}>
          <p className="lead">
            Unlike Captains of other gamemodes, Officers can be executed just
            like Heroes and count as Heroes for Feats/Perks. However, they will
            not feed revenge.
            <br />
            When slain, a new one comes on the third Pikemen wave after.
            <br />
            <br />
            <b>
              <u>Health</u>
            </b>
            <br />
            80 HP
            <br />
            <br />
            <b>
              <u>Damage</u>
            </b>
            <br />
            Light: 20
            <br />
            Heavy: 24
          </p>
        </div>
        <div id="BreachGuardian" />
        <Title text="Breach Guardian" />
        <div style={styledInfo}>
          <p className="lead">
            Will drop a bomb periodically. Receives special Revenge, which they
            can collect when facing only one enemy; however, on activation,
            their Revenge only activates a Revenge Auto-parry. They take 66%
            less damage from Feats (with some exceptions) and can be executed.
            Will heal Health to full if left alone for long enough.
            <br />
            <br />
            When slain, grants the Guardian Buff to all allies of the Killer who
            were in proximity to the Guardian (do not have to participate in
            killing the Guardian to gain the buff), on top of Revenge Activation
            and full Health/Stamina heal.
            <br />
            <br />
            Spawns 2 minutes and 30 seconds into Phase 2, respawns 2 minutes and
            30 seconds after being slain.
            <br />
            <br />
            <b>
              <u>Health</u>
            </b>
            <br />
            520 HP
            <br />
            <br />
            <b>
              <u>Damage</u>
            </b>
            <br />
            Light: 15
            <br />
            Heavy: 30 (Unblockable)
            <br />
            Bomb (Pugno Mortis): 35
          </p>
        </div>
        <div id="BreachCommander" />
        <Title text="Breach Commander" />
        <div style={styledInfo}>
          <p className="lead">
            Has similarities to the Guardian: can drop a bomb, has the same
            special Revenge, and receives 66% less damage from most Feats.
            <br />
            Commanders uniquely are counted as an allied Hero to the Defenders,
            thus they will benefit from Feats that provide allied shielding (ie.
            Umbral Shelter, Phalanx) on top of allied healing.
            <br />
            <br />
            Note: Killing the Commander does not grant Renown points.
            <br />
            <br />
            <b>
              <u>Health</u>
            </b>
            <br />
            855 HP
            <br />
            <br />
            <b>
              <u>Damage</u>
            </b>
            <br />
            Light: 20
            <br />
            Heavy: 30 (Unblockable)
            <br />
            Zone Attack: 35 (Unblockable) x3
            <br />
            Lunge: 48 (Knocks down, Unblockable)
            <br />
            Bomb (Pugno Mortis): 35
          </p>
        </div>
        <div id="BreachRenownSources" />
        <Title text="Renown Sources (Breach)" />
        <div style={styledInfo}>
          <p className="lead">
            <b>Hero Kill/Assist</b> - 48/#
            <br />
            48/# of Heroes & Officers who have partaken in the kill
            <br />
            Killer does not receive points for Obj score; those who Assist will.
            <br />
            <br />
            <b>Pikemen Kill</b> - 12
            <br />
            <br />
            <b>Archer Kill</b> - 9
            <br />
            <br />
            <b>Officer Kill/Assist</b> - 24/#
            <br />
            24/# of Heroes & Officers who have partaken in the kill
            <br />
            <br />
            <b>Reviving an Ally</b> - 20
            <br />
            <br />
            <b>Capturing, Recapturing or Fortifying a Side Point</b> - 20
            <br />
            Recapture occurs when Attackers have gained control of a Side Point,
            but Defenders capture the Point before it is Fortified.
            <br />
            <br />
            <b>Hit Ram with Cauldron</b> - 20
            <br />
            Inner Circle only (need to apply damage to Ram)
            <br />
            <br />
            <b>Guardian Kill/Assist</b>
            <br />
            1 Man: 480
            <br />
            2 Man: 300 for Killer, 240 for Assist
            <br />
            3 Man: 240 for Killer, 150 for Assist
            <br />
            4 Man: 150 for Killer, 120 for Assist
            <br />
            <br />
            <b>Defended Point</b> - 16
            <br />
            Stand on an Allied Side Point or Healing Zone, or near the Ram or
            Banner, when an enemy Hero dies on point/nearby.
            <br />
            <br />
            <b>Place Banner into Gate/Ram</b> - 50
            <br />
            <br />
            <b>Kill bonuses apply?</b> - Yes
          </p>
        </div>
        <div id="DeathmatchGamemodeInfo" />
        <Title text="Deathmatch Gamemode Info" />
        <div style={styledInfo}>
          <p className="lead">
            Deathmatch refers to the two free-for-all gamemodes, Elimination and
            Skirmish.
            <br />
            <br />
            Elimination is a Single-Pick, Best-of-5 gamemode. Each round is 4
            minutes long, and is won when a team has been fully wiped. If time
            runs out, whichever team has more Heroes alive wins the round.
            <br />
            <br />
            Skirmish is a Single-Pick free-for-all gamemode where you kill enemy
            units or Heroes to gain points for your team in order to place the
            opposing team into Breaking by reaching 1000 points (much like
            Dominion, but without Capture Points). A match of Skirmish lasts 20
            minutes.
          </p>
        </div>
        <div id="DeathmatchBoosts" />
        <Title text="Deathmatch Boosts" />
        <div style={styledInfo}>
          <p className="lead">
            <b>
              <u>Heal Boost</u>
            </b>
            <br />
            20 HP on pickup (+35 HP over 5 seconds)
            <br />
            <br />
            Elimination: Spawns at 0:20; Respawns every 30 seconds
            <br />
            Skirmish: Spawns at 0:30; Respawns every 40 seconds
            <br />
            <br />
            <b>
              <u>Star Boost</u>
            </b>
            <br />
            Access to all Feats for 30 seconds
            <br />
            <br />
            Skirmish Only
            <br />
            Spawns at 0:45; Respawns every 60 seconds
            <br />
            <br />
            <b>
              <u>Attack Boost</u>
            </b>
            <br />
            +55% bonus damage for 40 seconds
            <br />
            <br />
            Elimination: Spawns at 0:20; Respawns every 30 seconds
            <br />
            Skirmish: Spawns at 1:00; Respawns every 1 minute and 30 seconds
            <br />
            <br />
            <b>
              <u>Movement Speed Boost</u>
            </b>
            <br />
            +25% movement speed for 40 seconds
            <br />
            <br />
            Elimination: Spawns at 0:20; Respawns every 30 seconds
            <br />
            Skirmish: Spawns at 1:15; Respawns every 1 minute and 30 seconds
            <br />
            <br />
            <b>
              <u>Shield Boost</u>
            </b>
            <br />
            100 HP Shield for 60 seconds.
            <br />
            <br />
            Elimination: Spawns at 0:20; Respawns every 30 seconds
            <br />
            Skirmish: Spawns at 1:30; Respawns every 1 minute and 30 seconds
          </p>
        </div>
        <div id="EliminationRenownSources" />
        <Title text="Renown Sources (Elimination)" />
        <div style={styledInfo}>
          <p className="lead">
            <b>Hero Kill</b> - 18
            <br />
            <br />
            <b>Assist</b> - 6
            <br />
            <br />
            <b>Reviving an Ally</b> - 20
            <br />
            <br />
            <b>Execution</b> - 5
            <br />
            <br />
            <b>Kill bonuses apply?</b> - Yes
          </p>
        </div>
        <div id="SkirmishCaptains" />
        <Title text="Skirmish Captains" />
        <div style={styledInfo}>
          <p className="lead">
            Captains will spawn together with Soldiers, and will interchangably
            attack with Light and Heavy attacks. Will occassionally attempt
            Guardbreaks.
            <br />
            <br />
            <b>
              <u>Health</u>
            </b>
            <br />
            68 HP
            <br />
            <br />
            <b>
              <u>Damage</u>
            </b>
            <br />
            Light: 22 damage
            <br />
            Heavy: 35 damage
          </p>
        </div>
        <div id="SkirmishShieldCaptains" />
        <Title text="Skirmish Shield Captains" />
        <div style={styledInfo}>
          <p className="lead">
            On certain maps, a Shield Captain will spawn instead of normal
            Captains. They will only throw Heavy Attacks and Guardbreaks from
            neutral, but will also perform a Full Block Stance, akin to Warlord.
            <br />
            If they block an attack with the Stance, they will follow-up with a
            guaranteed Block-and-Stab.
            <br />
            <br />
            <b>
              <u>Health</u>
            </b>
            <br />
            50 HP
            <br />
            <br />
            <b>
              <u>Damage</u>
            </b>
            <br />
            Heavy: 14 damage
            <br />
            Block and Stab: 5 damage
          </p>
        </div>
        <div id="SkirmishRenownSources" />
        <Title text="Renown Sources (Skirmish)" />
        <div style={styledInfo}>
          <p className="lead">
            <b>Hero Kill</b> - 35
            <br />
            Awards 55 Points to your team's point pool.
            <br />
            <br />
            <b>Assist</b>
            <br />
            Vanguard - 5
            <br />
            Assassin - 3
            <br />
            Heavy - 10
            <br />
            Hybrid - 5
            <br />
            <br />
            <b>Revive</b>
            <br />
            Vanguard/Heavy - 35
            <br />
            Assassin/Hybrid - 30
            <br />
            <br />
            <b>Soldier Kill</b>
            <br />
            Vanguard - 3
            <br />
            Assassin - 1
            <br />
            Heavy/Hybrid - 2
            <br />
            Awards a point to your team's point pool.
            <br />
            <br />
            <b>Captain/Shield Captain Kill</b>
            <br />
            Vanguard - 10
            <br />
            Assassin - 12
            <br />
            Heavy - 8
            <br />
            Hybrid - 10
            <br />
            Awards 5 points to your team's point pool.
            <br />
            <br />
            <b>Soldier Kill</b>
            <br />
            Vanguard - 3
            <br />
            Assassin - 1
            <br />
            Heavy/Hybrid - 2
            <br />
            Awards a point to your team's point pool.
            <br />
            <br />
            <b>Execution</b>
            <br />
            Vanguard - 5
            <br />
            Assassin - 3
            <br />
            Heavy - 10
            <br />
            Hybrid - 5
            <br />
            <br />
            <b>Kill bonuses apply?</b> - Yes
            <br />
            <br />
            <b>Rampage</b> - 30
            <br />
            Awards 30 points to your team's point pool.
          </p>
        </div>
        <div id="TributeGamemodeInfo" />
        <Title text="Tribute Gamemode Info & Shrine Bonuses" />
        <div style={styledInfo}>
          <p className="lead">
            Tribute is an Objective gamemode, where the objective is to either
            have all three Offerrings in your team's shrines, or have more
            Offerings in your team's Shrines than the opposing team. A match
            lasts 10 minutes, with 2 minutes Overtime.
            <br />
            <br />
            Offerings spawn 30 seconds into the round. If a team attains all
            three Shrines, or gains the lead during Overtime, then there will be
            a countdown that varies between 1 minute and 10 seconds.
            <br />
            <br />
            Picking Offerings off the ground takes 1.5 seconds, while taking
            them from or placing them into a shrine takes 3 seconds.
            <br />
            Offerings slow the holder's movement speed by 25% and highlights
            them to the enemy team.
            <br />
            Every home Shrine with an Offering increases your respawn timer by 5
            seconds, up to an maximum of 25 seconds total for base Respawn time.
            <br />
            <br />
            <b>
              <u>Vindicator Shrine</u>
            </b>
            <br />
            Grants +15% damage to the team.
            <br />
            Also reduces time to pick up an Offering from the enemy's Shrine by
            a second.
            <br />
            <br />
            <b>
              <u>Guardian Shrine</u>
            </b>
            <br />
            Grants a 25-health shield that regenerates when out of combat.
            <br />
            Also increases time taken by Enemies to steal Offerings from home
            Shrines by 2 seconds.
            <br />
            <br />
            <b>
              <u>Oracle Shrine</u>
            </b>
            <br />
            See all enemy Heroes, both on the minimap and in the game (via an
            outline).
          </p>
        </div>
        <div id="TributeRenownSources" />
        <Title text="Renown Sources (Tribute)" />
        <div style={styledInfo}>
          <p className="lead">
            <b>Hero Kill</b> - 10
            <br />
            <br />
            <b>Assist</b> - 8
            <br />
            <br />
            <b>Revive</b> - 40
            <br />
            <br />
            <b>Execution</b> - 6-10
            <br />
            When enemy team has more players alive - 10
            <br />
            When both teams has equal players alive - 8
            <br />
            When your team has more players alive - 6
            <br />
            <br />
            <b>Placing/Stealing an offering</b> - 48
            <br />
            Occurs upon placing an Offering in one of your team's Shrines or
            upon picking up an Offering from one of the enemy team's Shrines.
            <br />
            <br />
            <b>Defending a Shrine</b> - 24
            <br />
            Occurs upon killing an enemy Hero while nearby to your team's Shrine
            that has an Offering in it.
            <br />
            <br />
            <b>Prevent Offering from being Stolen</b> - 24
            <br />
            Occurs upon attacking an enemy Hero who is attempting to take an
            Offering from one of your team's Shrines, causing them to fail the
            attempt.
            <br />
            <br />
            <b>Kill bonuses apply?</b> - Partially
          </p>
        </div>
      </center>
    </div>
  );
};

export default Information;
